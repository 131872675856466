import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import GuideCard from "./guide-card";
import { ContentfulAsset } from "@common/types/contentful-models";

export type GuideElement = {
  id: string;
  title: string;
  order: number;
  content: {
    raw: string;
  };
  image: ContentfulAsset;
};

const GuideElements: React.FC = () => {
  const data = useStaticQuery(
    graphql`
      query GuideCards {
        allContentfulGuideCards(
          filter: { metadata: { tags: { elemMatch: { contentful_id: { eq: "bitcoinGuide" } } } } }
          sort: { order: ASC, fields: order }
        ) {
          edges {
            node {
              id
              title
              order
              content {
                raw
                references {
                  ... on ContentfulAsset {
                    contentful_id
                    title
                    description
                    gatsbyImageData(width: 600)
                    __typename
                  }
                }
              }
              image {
                id
                description
                gatsbyImageData(height: 300)
              }
            }
          }
        }
      }
    `
  );
  const entries = data.allContentfulGuideCards.edges.map((edge: { node: GuideElement }) => edge.node);

  return (
    <div className="grid gap-8">
      {entries.map((node: GuideElement, index: number) => (
        <GuideCard key={index} member={node} />
      ))}
    </div>
  );
};
export default GuideElements;
