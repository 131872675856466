import Layout from "@components/layout";
import GuideElements from "@components/Modules/wissen/guide-elements";
import SEO from "@components/seo";
import Title from "@components/UI-Elements/Typography/Title";
import React from "react";

const title = "Der Bitcoin Einsteiger-Guide";
const description = "Dieser Einsteiger-Guide für Bitcoin erklärt in kurz und einfach was Bitcoin ist. Die enthaltenen Links geben dir die Möglichkeit noch tiefer in die Themen zu gelangen.";
const CryptoLinks: React.FC = () => {

  return (
    <Layout>
      <SEO title={title} description={description} />
      <div className="text-center pb-20">
        <Title>{title}</Title>
        <p>{description}</p>
      </div>
      <GuideElements />
    </Layout>
  );
};

export default CryptoLinks; 
