import clsx from "clsx";
import * as React from "react";

type Props = {
  className?: string;
  children: React.ReactNode;
};

export const Text: React.FC<Props> = ({ children, className }) => (
  <p className={className}>{children}</p>
);
export const Bold: React.FC<Props> = ({ children, className }) => (
  <b className={`font-bold ${className}`}>{children}</b>
);
export const Italic: React.FC<Props> = ({ children, className }) => (
  <i className={`italic break-all ${className}`}>{children}</i>
);
export const Underline: React.FC<Props> = ({ children, className }) => (
  <u className={`underline ${className}`}>{children}</u>
);
export const Blockquote: React.FC<Props> = ({ children, className }) => (
  <blockquote className={clsx("border-brand-primary my-3 rounded border-l-4 bg-gray-50 dark:bg-gray-700 p-3 italic", className)}>
    <>&quot;{children}&quot;</>
  </blockquote>
);
export const Article: React.FC<Props> = ({ children }) => (
  <article className="prose dark:prose-invert max-w-none">{children}</article>
);
