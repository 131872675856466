import * as React from "react";
import { GuideElement } from "./guide-elements";
import Card from "@components/UI-Elements/Card";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import RichTextRender from "@components/Core/Contentful/RichTextRender";
import Title from "@components/UI-Elements/Typography/Title";

type Props = {
  member?: GuideElement;
};

const GuideCard: React.FC<Props> = ({ member }) => {
  return (
    <Card className="p-3 sm:p-8">
      <div className="md:flex">
        <div className="flex-1">
          <Title level={2}>{member.title}</Title>
          <RichTextRender content={member.content} />
        </div>
        {member.image && (
          <div className="flex-1 text-center">
            <GatsbyImage image={getImage(member.image)} alt={member.title} />
          </div>
        )}
      </div>
    </Card>
  );
};

export default GuideCard;
