import * as React from "react";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import Link from "@components/UI-Elements/Navigation/Link";
import { Blockquote, Bold, Italic, Text, Underline } from "@components/UI-Elements/Typography/Text";
import Title from "@components/UI-Elements/Typography/Title";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import clsx from "clsx";

const options = {
  renderMark: {
    [MARKS.BOLD]: (text: React.ReactNode) => <Bold>{text}</Bold>,
    [MARKS.ITALIC]: (text: React.ReactNode) => <Italic>{text}</Italic>,
    [MARKS.UNDERLINE]: (text: React.ReactNode) => <Underline>{text}</Underline>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
      if (node.content.length === 1 && node.content[0].value === "") {
        return <br />;
      }
      return <Text>{children}</Text>;
    },
    [BLOCKS.HEADING_1]: (_: any, children: any) => <Title level={2} align="left">{children}</Title>,
    [BLOCKS.HEADING_2]: (_: any, children: any) => <Title level={2} align="left">{children}</Title>,
    [BLOCKS.HEADING_3]: (_: any, children: any) => <Title level={3} align="left">{children}</Title>,
    [BLOCKS.HEADING_4]: (_: any, children: any) => <Title level={4} align="left">{children}</Title>,
    [BLOCKS.HEADING_5]: (_: any, children: any) => <Title level={5} align="left">{children}</Title>,
    [BLOCKS.HEADING_6]: (_: any, children: any) => <Title level={6} align="left">{children}</Title>,
    [BLOCKS.UL_LIST]: (_: any, children: any) => <ul>{children}</ul>,
    [BLOCKS.OL_LIST]: (_: any, children: any) => <ol>{children}</ol>,
    [BLOCKS.LIST_ITEM]: (_: any, children: any) => <li>{children}</li>,
    [INLINES.HYPERLINK]: (node: any, children: any) => {
      const { uri } = node.data;

      return (
        <Link href={uri} external type="text">
          {children}
        </Link>
      );
    },
    [BLOCKS.HR]: () => <hr className="mb-6" />,
    [BLOCKS.QUOTE]: (children: any) => <Blockquote>{children.content[0].content[0].value}</Blockquote>,
    [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
      const { gatsbyImageData, description } = node.data.target;
      return (
        <div className="text-center">
          <GatsbyImage image={getImage(gatsbyImageData)} alt={description} />
        </div>
      );
    },
  },
};

export const richTextRenderer = (text: any) => renderRichText(text, options);

type Props = {
  content: { raw: string };
  className?: string;
};
const RichTextRender: React.FC<Props> = ({ content, className }) => {
  return <div className={clsx('prose dark:prose-invert max-w-none', className)}>{richTextRenderer(content)}</div>;
};
export default RichTextRender;